/**
 * Blog Page
 * @namespace BlogArticle
 */
import React, { useEffect, useState } from "react";
import config from "../../config/main.config";
import sharedConfig from "../../../shared/sharedConfig";
import { withTranslation } from "react-i18next";
import { Grid, Header } from "semantic-ui-react";
import BreadCrumbs from "../../components/breadcrumbs";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import moment from "moment";
import TransaltedBlogArticles from "../../../public/blog/articleTranslation.json"

function getArticle(link) {
	return new Promise((resolve) =>
		fetch(config.BASE_URL + "blog/article/" + link, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
			},
		})
			.then((response) => {
				if (response.status >= 200 && response.status < 400) {
					response.json().then((json) => {
						if (json.hasOwnProperty("header")) {
							return resolve(json);
						}
					});
				} else {
					console.error("ERROR on Fetch Article");
				}
			})
			.catch((error) => {
				console.error(error);
			}),
	);
}

const BlogArticlePage = (props) => {
	const { i18n } = props;
	const location = useLocation();

	const [breadCrumbData, setBreadCrumbData] = useState([]);
	const [articleToShow, setArticleToShow] = useState({});

	useEffect(() => {
		if (typeof window !== "undefined") {
			window.scrollTo(0, 0);
		}
		let bloglink;
		if (typeof props.store.article !== "undefined") {
			bloglink = props.store.article.blogLink;
		} else {
			bloglink = location.pathname.substring(
				location.pathname.lastIndexOf("/") + 1,
				location.pathname.length
			);
		}



		getArticle(bloglink).then((article) => {
			if (i18n.language !== 'de-DE') {
				const blogLink = location.pathname.substring(location.pathname.lastIndexOf("/") + 1, location.pathname.length);
				article.header = TransaltedBlogArticles[blogLink][i18n.language].header
				article.text = TransaltedBlogArticles[blogLink][i18n.language].text
				article.description = TransaltedBlogArticles[blogLink][i18n.language].description
			}
			setArticleToShow(article);
			if (
				article.hasOwnProperty("header") &&
				article.hasOwnProperty("blogLink")
			) {
				let newArray = [
					{ title: "BLOG", url: "/blog" },
					{
						title: article.header,
						url: "/blog-article/" + article.blogLink,
					},
				];
				setBreadCrumbData(newArray);
			}
		});
	}, []);

	return (
		<Grid
			itemScope
			itemType="https://schema.org/Article"
			className={"background-primary text-primary"}
			style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
		>
			<Helmet>
				<meta name="title" content={articleToShow.header} />
				<meta name="description" content={articleToShow.description} />

				<meta property="og:type" content="website" />
				<meta property="og:url" content={config.URL} />
				<meta property="og:title" content={articleToShow.header} />
				<meta property="og:description" content={articleToShow.description} />
				<meta
					property="og:image"
					content={
						config.BASE_URL_IMAGES + "all-images/" + articleToShow.thumbnail
					}
				/>

				<meta property="twitter:card" content="summary_large_image" />
				<meta property="twitter:url" content={config.URL} />
				<meta property="twitter:title" content={articleToShow.header} />
				<meta
					property="twitter:description"
					content={articleToShow.description}
				/>
				<meta
					property="twitter:image"
					content={
						config.BASE_URL_IMAGES + "all-images/" + articleToShow.thumbnail
					}
				/>
			</Helmet>
			<Grid.Row columns={3}>
				<Grid.Column />
				<Grid.Column>
					<BreadCrumbs breadCrumbData={breadCrumbData} />
				</Grid.Column>
				<Grid.Column />
			</Grid.Row>
			<Grid.Row columns={3} style={{ paddingTop: "4rem" }}>
				<Grid.Column />
				<Grid.Column className={"articleBody"} width={6}>
					<h1 className={"header-primary"} itemProp="headline">
						{articleToShow.header}{" "}
					</h1>
					<Header className={"header-primary"} itemProp="author" sub>
						{articleToShow.authorName}{" "}
						{moment(articleToShow.date).format("DD.MM.YYYY")}
					</Header>
					{articleToShow.blogImage && (
						<picture>
							<source
								media={"(min-width: 1024px)"}
								srcSet={
									config.BASE_URL_IMAGES +
									"desktop/" +
									(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(
										articleToShow.blogImage,
									)
										? articleToShow.blogImage.substring(
											0,
											articleToShow.blogImage.lastIndexOf("."),
										)
										: articleToShow.blogImage) +
									`.webp ${sharedConfig.IMAGE_SIZES.LARGE}w` +
									"," +
									config.BASE_URL_IMAGES +
									"desktop/" +
									articleToShow.blogImage
								}
							/>
							<source
								media={"(min-width: 321px)"}
								srcSet={
									config.BASE_URL_IMAGES +
									"tablet/" +
									(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(
										articleToShow.blogImage,
									)
										? articleToShow.blogImage.substring(
											0,
											articleToShow.blogImage.lastIndexOf("."),
										)
										: articleToShow.blogImage) +
									`.webp ${sharedConfig.IMAGE_SIZES.MEDIUM}w` +
									"," +
									config.BASE_URL_IMAGES +
									"tablet/" +
									articleToShow.blogImage
								}
							/>
							<source
								media={"(max-width: 320px)"}
								srcSet={
									config.BASE_URL_IMAGES +
									"mobile/" +
									(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(
										articleToShow.blogImage,
									)
										? articleToShow.blogImage.substring(
											0,
											articleToShow.blogImage.lastIndexOf("."),
										)
										: articleToShow.blogImage) +
									`.webp ${sharedConfig.IMAGE_SIZES.SMALL}w` +
									"," +
									config.BASE_URL_IMAGES +
									"mobile/" +
									articleToShow.blogImage
								}
							/>
							<img
								itemProp={"image"}
								className={"blogImage"}
								src={
									config.BASE_URL_IMAGES +
									"all-images/" +
									articleToShow.blogImage
								}
								alt={articleToShow.header}
							/>
						</picture>
					)}
				</Grid.Column>
				<Grid.Column />
			</Grid.Row>
			<Grid.Row columns={3}>
				<Grid.Column />
				<Grid.Column className={"articleBody"} width={6} itemProp="text">
					<div dangerouslySetInnerHTML={{ __html: `${articleToShow.text}` }} />
				</Grid.Column>
				<Grid.Column />
			</Grid.Row>
		</Grid>
	);
};

export default withTranslation(["common"])(BlogArticlePage);
