import React from "react";
import {withTranslation} from "react-i18next";
import {useServerData} from "../../state/serverDataContext";

import {getOneArticle} from "../../../server/helper/ssrHelper";
import config from "../../config/main.config";


import BlogArticlePage from "./BlogArticlePage";


const BlogArticle = () => {

    return (
        <BlogArticlePage store={useServerData()}/>
    )
}

BlogArticle.fetchData = (req) => {
    let articleID = ''
    if (req.params[0]) {
        articleID = req.params[0].substr(req.params[0].lastIndexOf('/') + 1, req.params[0].length);
    }
    return getOneArticle(articleID, config.BASE_URL).then(article => {
        return {article};
    })
}



export default withTranslation(['common'])(BlogArticle)
